export const STANDARDS = [


  {
    "category_num": 0,
    "category_title": "Standard Ratings",
    "category_group": "",
    "category_description": "Standard Ratings",
    "scoreInfo": [
    {
        "rating": "E",
        "bullets": [
            {
                "ex_text": "Exceptional Performance well exceeds expectations and is consistently outstanding."
            }
        ]
    },
    {
        "rating": "A",
        "bullets": [
            {
                "ex_text": "Above Expectations. Performance is consistently beyond expectations."
            }
        ]
    },
    {
        "rating": "S",
        "bullets": [
            {
                "ex_text": "Solid Performance. Performance consistently fulfills expectations and at times exceeds them."
            }
        ]
    },
    {
        "rating": "I",
        "bullets": [
            {
                "ex_text": "Improvement Needed. Performance does not consistently meet expectations."
            }
        ]
    },
    {
        "rating": "U",
        "bullets": [
            {
                "ex_text": "Unsatisfactory. Performance is consistently below expectations. Deficiencies should be addressed as noted in the performance appraisal."
            }
        ]
    }
] 

},


  {
    "category_num": 1,
    "category_title": "JOB KNOWLEDGE",
    "category_group": "",
    "category_description": "Performance as a result of job knowledge, job knowledge as a resource, and application of knowledge to achieve objectives",
    "scoreInfo": [
      {
        "rating": "E",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Exhibits superior knowledge and expertise in the most complex aspects of the job"
          },
          {
            "ex_num": 1,
            "ex_text": "Serves as an instructor/mentor"
          },
          {
            "ex_num": 2,
            "ex_text": "Uses knowledge to develop/ promote improvements"
          }
        ]
      },
      {
        "rating": "A",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Knowledge exceeds what is required to perform well"
          },
          {
            "ex_num": 1,
            "ex_text": "Serves, and is recognized, as a subject matter expert"
          },
          {
            "ex_num": 2,
            "ex_text": "Helps others improve their job knowledge for the successful achievement of team objectives"
          }
        ]
      },
      {
        "rating": "S",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Understands job functions and knows how to fulfill them"
          },
          {
            "ex_num": 1,
            "ex_text": "Keeps current in field, continues to gain knowledge and to share information"
          },
          {
            "ex_num": 2,
            "ex_text": "Applies knowledge for the achievement of objectives"
          }
        ]
      },
      {
        "rating": "I",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Needs to improve job knowledge to perform job satisfactorily"
          },
          {
            "ex_num": 1,
            "ex_text": "Needs to update job knowledge to reduce reliance on others"
          },
          {
            "ex_num": 2,
            "ex_text": "Needs to improve application of job knowledge to achieve objectives"
          }
        ]
      },
      {
        "rating": "U",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Has failed to obtain/ maintain job knowledge needed to perform job"
          },
          {
            "ex_num": 1,
            "ex_text": "Asks for help with most projects as knowledge is insufficient to function independently"
          },
          {
            "ex_num": 2,
            "ex_text": "Rarely knows how to apply job knowledge to achieve objectives"
          }
        ]
      }
    ]
  },
  {
    "category_num": 2,
    "category_title": "QUALITY",
    "category_group": "",
    "category_description": "Producing correct, precise results, working with degree of excellence, and learning from errors",
    "scoreInfo": [
      {
        "rating": "E",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Develops and implements ways to measure and improve quality"
          },
          {
            "ex_num": 1,
            "ex_text": "Other employees use his/her work as model"
          },
          {
            "ex_num": 2,
            "ex_text": "Mentors/trains others in quality improvement processes"
          }
        ]
      },
      {
        "rating": "A",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Regularly evaluates situation and suggests ways to improve quality"
          },
          {
            "ex_num": 1,
            "ex_text": "Uses information from all sources to achieve thorough and accurate results"
          },
          {
            "ex_num": 2,
            "ex_text": "Uses errors as learning opportunity, sharing with team"
          }
        ]
      },
      {
        "rating": "S",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Checks results to ensure completion"
          },
          {
            "ex_num": 1,
            "ex_text": "Quality of outputs meets standards"
          },
          {
            "ex_num": 2,
            "ex_text": "Learns from, and avoids duplicating errors"
          }
        ]
      },
      {
        "rating": "I",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Inconsistently checks results to ensure completion"
          },
          {
            "ex_num": 1,
            "ex_text": "Needs to improve the quality of work to meet standards"
          },
          {
            "ex_num": 2,
            "ex_text": "Needs to focus on learning from past errors to reduce error rate"
          }
        ]
      },
      {
        "rating": "U",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Does not check results to ensure completion"
          },
          {
            "ex_num": 1,
            "ex_text": "Quality of outputs does not meet standards"
          },
          {
            "ex_num": 2,
            "ex_text": "Has not demonstrated ability to understand how to improve quality and learn from past errors"
          }
        ]
      }
    ]
  },
  {
    "category_num": 3,
    "category_title": "PLANNING/ ORGANIZATION",
    "category_group": "",
    "category_description": "Developing action plans, organizing and prioritizing work, and keeping track of multiple tasks/projects",
    "scoreInfo": [
      {
        "rating": "E",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Mentors others on effective planning and organizing"
          },
          {
            "ex_num": 1,
            "ex_text": "Develops plans for unit that maximize results and streamline processes"
          },
          {
            "ex_num": 2,
            "ex_text": "Can successfully manage multiple projects/tasks at the same time"
          }
        ]
      },
      {
        "rating": "A",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Anticipates barriers and develops contingency plans to overcome them"
          },
          {
            "ex_num": 1,
            "ex_text": "Assists others with planning/ organizing work and resources"
          },
          {
            "ex_num": 2,
            "ex_text": "Assists with the tracking of multiple tasks/projects outside immediate area of responsibility"
          }
        ]
      },
      {
        "rating": "S",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Understands objectives and creates clear, thoughtful action plans"
          },
          {
            "ex_num": 1,
            "ex_text": "Prioritizes/organizes work and selects efficient methods/processes"
          },
          {
            "ex_num": 2,
            "ex_text": "Knows status of assigned tasks and location of materials"
          }
        ]
      },
      {
        "rating": "I",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Needs to improve ability to create clear, practical action plans"
          },
          {
            "ex_num": 1,
            "ex_text": "Needs to improve organization and prioritization of work"
          },
          {
            "ex_num": 2,
            "ex_text": "Needs to strengthen concentration when working on multiple tasks/projects"
          }
        ]
      },
      {
        "rating": "U",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Fails to create action plans to accommodate workload and work schedule"
          },
          {
            "ex_num": 1,
            "ex_text": "Rarely organizes and prioritizes work without direction"
          },
          {
            "ex_num": 2,
            "ex_text": "Has not demonstrated ability to track and complete multiple tasks/projects"
          }
        ]
      }
    ]
  },
  {
    "category_num": 4,
    "category_title": "PRODUCTIVITY",
    "category_group": "",
    "category_description": "Using work time to achieve desired objectives, working efficiently with proper tools, overcoming barriers",
    "scoreInfo": [
      {
        "rating": "E",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Exceeds agreed results and time frame"
          },
          {
            "ex_num": 1,
            "ex_text": "Coaches others on the use of the best tools and processes to follow for maximum efficiency"
          },
          {
            "ex_num": 2,
            "ex_text": "Anticipates unforeseen workload needs and finds ways to meet them"
          }
        ]
      },
      {
        "rating": "A",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Regularly exceeds agreed results and/or time frame"
          },
          {
            "ex_num": 1,
            "ex_text": "Has complete understanding of the best tools and processes to follow for maximum efficiency"
          },
          {
            "ex_num": 2,
            "ex_text": "Assists others on team to overcome barriers and work more efficiently"
          }
        ]
      },
      {
        "rating": "S",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Produces agreed results in agreed time frame"
          },
          {
            "ex_num": 1,
            "ex_text": "Focuses on tasks and use of the best job tools for tasks"
          },
          {
            "ex_num": 2,
            "ex_text": "Informs supervisor if delays or barriers are encountered"
          }
        ]
      },
      {
        "rating": "I",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Needs improvement to produce agreed results in agreed time frame"
          },
          {
            "ex_num": 1,
            "ex_text": "Needs to improve use of tools and processes to complete tasks more efficiently"
          },
          {
            "ex_num": 2,
            "ex_text": "Needs guidance more often times than not to overcome barriers"
          }
        ]
      },
      {
        "rating": "U",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Does not produce agreed results in agreed time frame"
          },
          {
            "ex_num": 1,
            "ex_text": "Has not shown sufficient understanding of best tools and processes to do job"
          },
          {
            "ex_num": 2,
            "ex_text": "Relies on assistance from others to overcome barriers"
          }
        ]
      }
    ]
  },
  {
    "category_num": 5,
    "category_title": "INITIATIVE/INNOVATION",
    "category_group": "",
    "category_description": "Taking action without needing direction to do so;Adapting to change, finding new methods, and contributing to results",
    "scoreInfo": [
      {
        "rating": "E",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Shows strong initiative in every situation"
          },
          {
            "ex_num": 1,
            "ex_text": "Contributes to process improvement irrespective of individual benefit(s)"
          },
          {
            "ex_num": 2,
            "ex_text": "Assesses risks, anticipates consequences and takes preventative action"
          },
          {
            "ex_num": 3,
            "ex_text": "Develops creative strategies and promotes creative thinking"
          },
          {
            "ex_num": 4,
            "ex_text": "Implements improvements which, measured by impact, frequency and effort, are successful"
          },
          {
            "ex_num": 5,
            "ex_text": "Leads others through change by keeping focused on desired objectives"
          }
        ]
      },
      {
        "rating": "A",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Takes action whenever the opportunity arises"
          },
          {
            "ex_num": 1,
            "ex_text": "Monitors results to ensure desired outcome"
          },
          {
            "ex_num": 2,
            "ex_text": "Recognizes and meets needs outside areas of responsibility"
          },
          {
            "ex_num": 3,
            "ex_text": "Generates enthusiasm for new ideas"
          },
          {
            "ex_num": 4,
            "ex_text": "Thinks creatively to find better, more efficient ways to do things within immediate area of responsibility"
          },
          {
            "ex_num": 5,
            "ex_text": "Assesses results and develops plans for improvements"
          }
        ]
      },
      {
        "rating": "S",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Builds on suggestions of others and puts ideas into action"
          },
          {
            "ex_num": 1,
            "ex_text": "Evaluates current processes and suggests new methods/ improvements"
          },
          {
            "ex_num": 2,
            "ex_text": "Recognizes and meets needs in areas of responsibility"
          },
          {
            "ex_num": 3,
            "ex_text": "Adapts to change readily"
          },
          {
            "ex_num": 4,
            "ex_text": "Seeks learning opportunities that foster new approaches"
          },
          {
            "ex_num": 5,
            "ex_text": "Provides ideas/suggestions/ solutions within immediate area of responsibility"
          }
        ]
      },
      {
        "rating": "I",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Relies on direction before taking action"
          },
          {
            "ex_num": 1,
            "ex_text": "Does not routinely contribute to process improvement"
          },
          {
            "ex_num": 2,
            "ex_text": "Needs to improve ability to recognize and support needs in areas of responsibility"
          },
          {
            "ex_num": 3,
            "ex_text": "Needs to be more flexible and positive to adapt to change"
          },
          {
            "ex_num": 4,
            "ex_text": "Does not go out of one's way to support or learn new approaches"
          },
          {
            "ex_num": 5,
            "ex_text": "Rarely contributes ideas/ suggestions/solutions to achieve results"
          }
        ]
      },
      {
        "rating": "U",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Fails to take action even when directed to do so"
          },
          {
            "ex_num": 1,
            "ex_text": "Does not contribute to process improvement"
          },
          {
            "ex_num": 2,
            "ex_text": "Does not recognize or support needs in areas of responsibility"
          },
          {
            "ex_num": 3,
            "ex_text": "Inflexible and negative when faced with change"
          },
          {
            "ex_num": 4,
            "ex_text": "Has not demonstrated willingness or ability to find/ learn new methods to improve processes"
          },
          {
            "ex_num": 5,
            "ex_text": "Needs to be led through the change process to produce desired results"
          }
        ]
      }
    ]
  },
  {
    "category_num": 6,
    "category_title": "TEAMWORK/ COOPERATION",
    "category_group": "",
    "category_description": "Working well with others, supporting team missions, and participating fully in shared efforts",
    "scoreInfo": [
      {
        "rating": "E",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Exemplifies harmonious working relationships"
          },
          {
            "ex_num": 1,
            "ex_text": "Drives and inspires others to exceed team goals"
          },
          {
            "ex_num": 2,
            "ex_text": "Serves as a team leader, facilitator, or motivator"
          }
        ]
      },
      {
        "rating": "A",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Assists others and picks up slack"
          },
          {
            "ex_num": 1,
            "ex_text": "Helps develop team mission/ plans and coordinates resolutions for problems as they occur"
          },
          {
            "ex_num": 2,
            "ex_text": "Helps pull team together and motivates others to work together harmoniously"
          }
        ]
      },
      {
        "rating": "S",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Builds rapport, supports suggestions of others and develops shared solutions"
          },
          {
            "ex_num": 1,
            "ex_text": "Is dedicated to fulfilling team mission and objectives"
          },
          {
            "ex_num": 2,
            "ex_text": "Does one's share within immediate area of responsibility"
          }
        ]
      },
      {
        "rating": "I",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Does not participate well on teams"
          },
          {
            "ex_num": 1,
            "ex_text": "Motivated more for his/her own goals than for those of the team"
          },
          {
            "ex_num": 2,
            "ex_text": "Has occasionally failed to coordinate with and contribute to work of team"
          }
        ]
      },
      {
        "rating": "U",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Obstructs team's progress"
          },
          {
            "ex_num": 1,
            "ex_text": "Does not support and contribute to team mission"
          },
          {
            "ex_num": 2,
            "ex_text": "Has consistently failed to achieve results as team member"
          }
        ]
      }
    ]
  },
  {
    "category_num": 7,
    "category_title": "DEPENDABILITY",
    "category_group": "",
    "category_description": "Being present and accountable, following through, and meeting deadlines",
    "scoreInfo": [
      {
        "rating": "E",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Serves as model for attendance and punctuality"
          },
          {
            "ex_num": 1,
            "ex_text": "Always delivers on promises"
          },
          {
            "ex_num": 2,
            "ex_text": "Assists others in meeting/exceeding deadlines and in understanding priorities"
          }
        ]
      },
      {
        "rating": "A",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Initiates backup coverage plans for planned absences"
          },
          {
            "ex_num": 1,
            "ex_text": "High level of accountability for projects"
          },
          {
            "ex_num": 2,
            "ex_text": "Does not disappoint when deadlines are tight"
          }
        ]
      },
      {
        "rating": "S",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Manages absences to minimize impact on office workflow"
          },
          {
            "ex_num": 1,
            "ex_text": "Conscientious worker who consistently follows through with assignments"
          },
          {
            "ex_num": 2,
            "ex_text": "Meets deadlines or unit standards for timely results"
          }
        ]
      },
      {
        "rating": "I",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Needs improvement in ensuring coverage during absences to lessen impact on office workflow"
          },
          {
            "ex_num": 1,
            "ex_text": "Needs to improve follow-through and completion of assigned tasks"
          },
          {
            "ex_num": 2,
            "ex_text": "Has occasionally missed deadlines"
          }
        ]
      },
      {
        "rating": "U",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Absences have negatively impacted workflow in office"
          },
          {
            "ex_num": 1,
            "ex_text": "Fails to follow through and complete assigned tasks"
          },
          {
            "ex_num": 2,
            "ex_text": "Has failed to consistently meet deadlines"
          }
        ]
      }
    ]
  },
  {
    "category_num": 8,
    "category_title": "COMMUNICATION",
    "category_group": "",
    "category_description": "Listening and exchanging information to achieve work objectives",
    "scoreInfo": [
      {
        "rating": "E",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Mentors others in improving their listening/communication skills"
          },
          {
            "ex_num": 1,
            "ex_text": "Is an articulate spokesperson for team's views"
          },
          {
            "ex_num": 2,
            "ex_text": "Takes a lead role in fostering appropriate communication amongst involved parties to achieve objectives"
          }
        ]
      },
      {
        "rating": "A",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Accurately interprets and conveys to others what is being said"
          },
          {
            "ex_num": 1,
            "ex_text": "Communicates reasons behind facts/results and confirms that others have necessary information"
          },
          {
            "ex_num": 2,
            "ex_text": "Initiates constructive dialogue and guides positive outcomes"
          }
        ]
      },
      {
        "rating": "S",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Understands and uses active/reflective listening"
          },
          {
            "ex_num": 1,
            "ex_text": "Expresses things clearly and confirms understanding"
          },
          {
            "ex_num": 2,
            "ex_text": "Anticipates information needs and shares relevant information in a timely way"
          }
        ]
      },
      {
        "rating": "I",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Needs improvement in listening to and receiving the ideas/ views of others"
          },
          {
            "ex_num": 1,
            "ex_text": "Could learn how to better communicate and articulate thoughts and ideas with others"
          },
          {
            "ex_num": 2,
            "ex_text": "Is occasionally unwilling to share or receive information to achieve work objectives"
          }
        ]
      },
      {
        "rating": "U",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Often misunderstands or does not listen to the ideas/ views of others"
          },
          {
            "ex_num": 1,
            "ex_text": "Hard to understand the points of communication"
          },
          {
            "ex_num": 2,
            "ex_text": "Is unable or unwilling to share or use shared information to achieve work objectives"
          }
        ]
      }
    ]
  },
  {
    "category_num": 9,
    "category_title": "9. UCSD STANDARDS",
    "category_group": "",
    "category_description": "Summary Rating for all UCSD Campus-Wide Standards",
    "scoreInfo": [
      {
        "rating": "E",
        "bullets": [
          {
            "ex_num": 1,
            "ex_text": "Exceptional Performance well exceeds expectations and is consistently outstanding."
          }
        ]
      },
      {
        "rating": "A",
        "bullets": [
          {
            "ex_num": 1,
            "ex_text": "Above Expectations. Performance is consistently beyond expectations."
          }
        ]
      },
      {
        "rating": "S",
        "bullets": [
          {
            "ex_num": 1,
            "ex_text": "Solid Performance. Performance consistently fulfills expectations and at times exceeds them."
          }
        ]
      },
      {
        "rating": "I",
        "bullets": [
          {
            "ex_num": 1,
            "ex_text": "Improvement Needed. Performance does not consistently meet expectations."
          }
        ]
      },
      {
        "rating": "U",
        "bullets": [
          {
            "ex_num": 1,
            "ex_text": "Unsatisfactory. Performance is consistently below expectations. Deficiencies should be addressed as noted in the performance appraisal."
          }
        ]
      }
    ]
  },
  {
    "category_num": 10,
    "category_title": "LEADERSHIP",
    "category_group": "SUPERVISORY STANDARD",
    "category_description": "Creating a climate of trust and mutual respect, creating/ articulating unit goals/vision, increasing potential for employees to be productive and to feel welcome, valued and motivated.",
    "scoreInfo": [
      {
        "rating": "E",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Clearly understands leadership as facilitating the success of team"
          },
          {
            "ex_num": 1,
            "ex_text": "Effectively communicates \"Big Picture\" viewpoint"
          },
          {
            "ex_num": 2,
            "ex_text": "Takes a lead role in planning/implementing programs that seek to further train, motivate and develop staff"
          }
        ]
      },
      {
        "rating": "A",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Has the confidence of staff because he/she is concerned with staff's personal welfare and development"
          },
          {
            "ex_num": 1,
            "ex_text": "Has a participative approach and manages people's expectations"
          },
          {
            "ex_num": 2,
            "ex_text": "Looks for opportunities to further train, motivate, and develop staff"
          }
        ]
      },
      {
        "rating": "S",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Creates a climate where staff trusts and supports leadership and each other"
          },
          {
            "ex_num": 1,
            "ex_text": "Involves staff in creating goals/vision and sets reasonable expectations"
          },
          {
            "ex_num": 2,
            "ex_text": "Shows strength in leading, training, motivating, and developing staff"
          }
        ]
      },
      {
        "rating": "I",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Needs to change approach so staff feel more supported and trusting of leadership"
          },
          {
            "ex_num": 1,
            "ex_text": "Creates/sets unrealistic unit goals and fails to communicate them with staff"
          },
          {
            "ex_num": 2,
            "ex_text": "Needs to learn how to be a more effective trainer and motivator to develop and direct staff"
          }
        ]
      },
      {
        "rating": "U",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Has created a climate where staff feel unsupported and distrustful of leadership"
          },
          {
            "ex_num": 1,
            "ex_text": "Appears unable to develop/articulate unit goals/vision"
          },
          {
            "ex_num": 2,
            "ex_text": "Fails to train, motivate, direct and/or develop staff"
          }
        ]
      }
    ]
  },


  {
    "category_num": 11,
    "category_title": "PERFORMANCE MANAGEMENT",
    "category_group": "SUPERVISORY STANDARD",
    "category_description": "Managing employee performance in alignment with the mission and goals of the department or unit and consistent with relevant policies and collective bargaining agreements.",
    "scoreInfo": [
      {
        "rating": "E",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Inspires staff to learn and be resourceful"
          },
          {
            "ex_num": 1,
            "ex_text": "Always gives meaningful and constructive feedback to staff"
          },
          {
            "ex_num": 2,
            "ex_text": "Excellent role model for continuous learning & development"
          }
        ]
      },
      {
        "rating": "A",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Is dedicated to staff's continuous learning and development at job"
          },
          {
            "ex_num": 1,
            "ex_text": "Meets regularly with staff to review performance and the achievement of individual/ team performance goals"
          },
          {
            "ex_num": 2,
            "ex_text": "Invests time to teach and mentor staff at how to create and complete development plans"
          }
        ]
      },
      {
        "rating": "S",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Ensures that staff have the training and resources needed for job success"
          },
          {
            "ex_num": 1,
            "ex_text": "Provides regular feedback to staff on issues relating to their performance and completes performance appraisal as required"
          },
          {
            "ex_num": 2,
            "ex_text": "Effectively assists staff with creating and fulfilling realistic development plans"
          }
        ]
      },
      {
        "rating": "I",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Needs to identify how to better equip staff with resources and training"
          },
          {
            "ex_num": 1,
            "ex_text": "Needs to improve the timeliness and completion of staff performance appraisal form"
          },
          {
            "ex_num": 2,
            "ex_text": "Needs to be more effective at helping staff create realistic development plans"
          }
        ]
      },
      {
        "rating": "U",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Staff does not have needed resources or training to be successful at job"
          },
          {
            "ex_num": 1,
            "ex_text": "Fails to complete performance appraisal form"
          },
          {
            "ex_num": 2,
            "ex_text": "Fails to assist staff with creating professional development plans"
          }
        ]
      }
    ]
  },


  {
    "category_num": 12,
    "category_title": "ORGANIZATIONAL ACCOUNTABILITY",
    "category_group": "SUPERVISORY STANDARD",
    "category_description": "Delegating authority consistent with the UCSD Principles of Accountability.",
    "scoreInfo": [
      {
        "rating": "E",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Has strengthened department considerably with effective delegation"
          },
          {
            "ex_num": 1,
            "ex_text": "Makes decisions based on facts and best interests of the organization"
          },
          {
            "ex_num": 2,
            "ex_text": "Contributes to the success of and takes accountability for the failures of the organization"
          }
        ]
      },
      {
        "rating": "A",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Uses delegation to develop staff"
          },
          {
            "ex_num": 1,
            "ex_text": "Chooses staff wisely to take control of responsibilities"
          },
          {
            "ex_num": 2,
            "ex_text": "Takes accountability and uses sound judgment to protect the interests of the organization"
          }
        ]
      },
      {
        "rating": "S",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Delegates fairly and appropriately to staff who are in a position to have authority"
          },
          {
            "ex_num": 1,
            "ex_text": "Considers staff's capabilities before delegating"
          },
          {
            "ex_num": 2,
            "ex_text": "Shows good judgment and weighs risks to organization before delegating authority"
          }
        ]
      },
      {
        "rating": "I",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Needs to improve understanding of policies governing appropriate delegation of authority"
          },
          {
            "ex_num": 1,
            "ex_text": "Needs to give clear directions or goals when delegating authority"
          },
          {
            "ex_num": 2,
            "ex_text": "Needs to improve judgment in weighing risks to organization before delegating authority"
          }
        ]
      },
      {
        "rating": "U",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Delegates to staff who are not in a position to accept authority"
          },
          {
            "ex_num": 1,
            "ex_text": "Delegates without direction or support"
          },
          {
            "ex_num": 2,
            "ex_text": "Fails to show good judgment in assessing risks when delegating authority"
          }
        ]
      }
    ]
  },



  {
    "category_num": 13,
    "category_title": "RESOURCE MANAGEMENT AND PLANNING",
    "category_group": "SUPERVISOR STANDARD",
    "category_description": "Managing available resources efficiently to provide the best services possible while enabling employees to achieve their work goals.",
    "scoreInfo": [
      {
        "rating": "E",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Organizes people and resources for maximum efficiency and success"
          },
          {
            "ex_num": 1,
            "ex_text": "Uses innovative ways to save that impact others outside of immediate area of responsibility"
          },
          {
            "ex_num": 2,
            "ex_text": "Excellent manager and forecaster of resources"
          }
        ]
      },
      {
        "rating": "A",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Suggests ways to save money and resources"
          },
          {
            "ex_num": 1,
            "ex_text": "Uses time and resources effectively with careful consideration of needs outside immediate area of responsibility"
          },
          {
            "ex_num": 2,
            "ex_text": "Anticipates resource needs and makes accommodations before it becomes an issue"
          }
        ]
      },
      {
        "rating": "S",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Treats resources carefully to provide the best services possible to employees and customers"
          },
          {
            "ex_num": 1,
            "ex_text": "Coordinates resource use with others"
          },
          {
            "ex_num": 2,
            "ex_text": "Works within given budget and allocated personnel"
          }
        ]
      },
      {
        "rating": "I",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Needs to improve management and use of resources to effectively meet organizational objectives"
          },
          {
            "ex_num": 1,
            "ex_text": "Uses resources without informing others"
          },
          {
            "ex_num": 2,
            "ex_text": "Consistently under-anticipates resource needs"
          }
        ]
      },
      {
        "rating": "U",
        "bullets": [
          {
            "ex_num": 0,
            "ex_text": "Does not effectively manage University resources to meet the needs of organization"
          },
          {
            "ex_num": 1,
            "ex_text": "Does not consider needs of others when using or managing resources"
          },
          {
            "ex_num": 2,
            "ex_text": "Fails to make contingency plans when faced with budgetary restrictions"
          }
        ]
      }
    ]
  },

];

export const GENERIC_STANDARD = STANDARDS[0];
