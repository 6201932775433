import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ConfirmationService } from "primeng/api";

import { ApiService } from "../api.service";


@Component({
  selector: 'app-jd-select',
  templateUrl: './jd-select.component.html',
  styleUrls: ['./jd-select.component.css']
})
export class JdSelectComponent implements OnInit {

  @Output() selection = new EventEmitter<any>(); // FIXME -- should be typed

  @Input() dialogOpen: boolean;
  @Input() headerString = "select jd";

  private searchJdNumber;
  private searchJdVersion = 1;
  private searchJdVersionId;

  private foundJobcards = [];
  private selectedJobcardIndex = 0;

  constructor(
    private readonly api: ApiService,
    private readonly confirmationService: ConfirmationService) { }

  ngOnInit() {
  }

  onTabChange(anEvent) {
    console.log("tab change event", anEvent);
    this.selectedJobcardIndex = anEvent.index;
  }

  private search() {
    console.log("searching ");
    this.api.getJdByJdNumber(this.searchJdNumber, this.searchJdVersion).subscribe(
      jds => {
        this.foundJobcards = jds
        this.selectedJobcardIndex = 0;
        if ( jds.length === 0 ) {
          this.confirmationService.confirm({
            message: "No job descriptions found...",
            rejectVisible: false,
            acceptLabel: "OK",
          });
        }
      },
      error => console.log("error searching for jobcards ", error)
    );
  }

  private selectionCancelled() {
    this.reset();
    this.selection.emit({
      action: "CANCELLED"
    });
  }

  /**
  * reset for next search invocation of this dialog
  */
  private reset() {
    this.searchJdNumber     = "";
    this.searchJdVersion    = 1;
    this.searchJdVersionId  = "";
    this.foundJobcards      = [];
    this.selectedJobcardIndex = 0;
  }

  private jdSelected() {
    const selectedJd = this.foundJobcards[this.selectedJobcardIndex];
    this.reset();
    this.selection.emit({
      action: "SELECTED",
      jd: selectedJd
    });
  }

}
