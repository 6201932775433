import { Component, OnInit } from '@angular/core';
import { ErrorService } from "../error.service";
import { ApiService   } from "../api.service";

@Component({
  selector: 'app-unit-list',
  templateUrl: './unit-list.component.html',
  styleUrls: ['./unit-list.component.css']
})
export class UnitListComponent implements OnInit {

  unitList = [];

  constructor(private errorService: ErrorService,
    private api: ApiService) { }

  ngOnInit() {
    this.api.getAllDeptInfo().subscribe(
      unitList => this.unitList = unitList,
      error    => this.errorService.logObject("unitlist/dept info err: ", error)
    );
  }
}
