import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ErrorService {
  public log(obj) {
    console.error(obj);
  }
  public logObject(str, obj) {
    console.error(str, obj);
  }
}
