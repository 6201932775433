import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { ApiService } from "../api.service";
import { ErrorService } from "../error.service";

import { SPA_STATUS_LOOKUP } from "../spa/states";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  private latestSpa;
  private employeesSupervised = [];
  private indirectEmployeesSupervised = [];

  constructor(private api: ApiService, 
    private errorService: ErrorService,
    private titleService: Title) { 
      this.titleService.setTitle(
        "UCSD Staff Performace Appraisals - Employee Home Page"
      );
    }

  private getStatusInfo(aSpa) {
    return SPA_STATUS_LOOKUP[aSpa.evalStatus];
  }

  ngOnInit() {
    this.api.getCurrentUserSpaList().subscribe(
      list => console.log("spa list: ", list)
    );
    this.api.getCurrentUserLatestSpa().subscribe( 
      spa => this.latestSpa = spa,
      err => this.errorService.logObject("getCurUserLatest/Home error: ", err)
    );
    this.api.getBasicInfoBySup().subscribe( 
      list => this.employeesSupervised = list,
      err => this.errorService.logObject("getBasicInfoBySup/Home error: ", err)
    );
    this.api.getIndirectBasicInfoBySup().subscribe( 
      list => this.indirectEmployeesSupervised = list,
      err => this.errorService.logObject("indirectBasic/Home error: ", err)
    );
  }

}
