import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

import { ConfirmationService } from "primeng/api";

import { ApiService } from "../api.service";
import { ErrorService } from "../error.service";

import { SpaCreateRequest } from "../types/SpaCreateRequest";

import { SPA_STATUS_LIST, SPA_STATUS_LOOKUP } from "../spa/states";

import * as _ from "lodash";

@Component({
  selector: 'app-unit-home',
  templateUrl: './unit-home.component.html',
  styleUrls: ['./unit-home.component.css']
})
export class UnitHomeComponent implements OnInit, OnDestroy {

  private deptCode;
  private routeSub;

  private unitInfo;
  private people = [];
  private currentCycle;
  private readonly DEFAULT_CYCLE = "2018/19";

/*
  public readonly cycleList: Array<SelectItem> = [ 
    {label: "2017/18", value: { name: "2017/18", code: "2017/18"}}, 
    {label: "2018/19", value: { name: "2018/19", code: "2018/19"}}, 
    {label: "2019/20", value: { name: "2019/20", code: "2019/20"}}, 
    {label: "PROBATION", value: { name: "PROBATION", code: "PROBATION"}},
    {label: "SPECIAL", value: { name: "SPECIAL", code: "SPECIAL"}}];
    */

  private readonly cycleList = [
    "2017/18", "2018/19", "PROBATION", "SPECIAL"
  ];

  private newSpaDialogVisible = false;
  private newSpaInfo = {
    name: "unknown",
    empno: -999,
    cycle: undefined
  };

  constructor(private api: ApiService, 
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private errorService: ErrorService) { }

  private getStatusDescription(aStatus) {
    const status = SPA_STATUS_LOOKUP[aStatus];
    return status ? status.description : "unknown status";
  }

  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      this.deptCode = params["deptCode"];
      this.loadUnitInfo();
      this.loadPeople();
    });
  }

  private createMissingSpasCurrentYear(repType) {
    const hasNoCurrentYearEval = person => 
      ! (person.olderEvals && person.olderEvals.map(e => e.cycle).includes(this.currentCycle));

    const repFilter = 
      repType == '99' ? p => p.empCbuc == '99' : p => p.empCbuc != '99';

    const empnos = this.people
        .filter(p => hasNoCurrentYearEval(p))
        .filter(repFilter)
        .map(p => p.empno);

    console.log("creating for ", empnos);

    this.api.batchCreateSpas(empnos, this.currentCycle).subscribe(
      results => this.loadPeople(),
      error => console.log("unit home batch create api error", error)
    );
  }

  private releaseCurrentYear() {
    const hasCurrentYearEval = person => 
      person.olderEvals && person.olderEvals.map(e => e.cycle).includes(this.currentCycle);

    const getCurrentUnreleasedEvals = person => 
      person.olderEvals
        .filter(e => e.cycle == this.currentCycle && e.hrStatus != "R");

    const peopleWithCurrent = this.people.filter(p => hasCurrentYearEval(p));
    let spasToRelease = [];
    peopleWithCurrent.forEach( 
      p => spasToRelease = spasToRelease.concat(getCurrentUnreleasedEvals(p))
    );
    const spaIdsToRelease = spasToRelease.map(spa => spa.id);
    //console.log("would release ", spasToRelease);
    console.log("batch releasing spa ids", spaIdsToRelease);
    this.api.batchReleaseSpas(spaIdsToRelease).subscribe(
      results => this.loadPeople(),
      error => console.log("unit home batch release api error", error)
    );

  }

  private batchNewSpas(repType) {
    this.confirmationService.confirm({
      message: `Really create all SPA's for ${repType}s using cycle ${this.currentCycle}?`,
      accept: () => {
        console.log("batch confirmation accepted");
        this.createMissingSpasCurrentYear(repType);
      },
      reject: () => console.log("batch confirmation rejected")
    });
  }

  private batchRelease() {
    this.confirmationService.confirm({
      message: `Really relase all SPA's for ${this.currentCycle}?`,
      accept: () => {
        console.log("batch release confirmation accepted");
        this.releaseCurrentYear();
      },
      reject: () => console.log("batch release confirmation rejected")
    });
  }

  private loadUnitInfo() {
    this.api.getDeptInfo(this.deptCode).subscribe(
      unitInfo => {
        this.unitInfo = unitInfo;
        console.log("unit info ", unitInfo);
      },
      error => this.errorService.logObject("lui/dept info err: ", error)
    );
  }

  private loadJdInfo() {
    const addJdInfoToPeople = jdList => {
      const jdMap = _.groupBy(jdList, "JDVersionID");
      this.people.forEach(p => {
        const mapEntry = jdMap[p.JDVersionID];
        if ( mapEntry && mapEntry.length > 0) {
          const jd = mapEntry[0];
          //p.PositionID = jd.PositionID;
          console.log("assinging jdlibraryid ", jd.JDLibraryID);
          p.JDLibraryID = jd.JDLibraryID;
          p.VersionNo  = jd.VersionNo;
          p.PositionCurrentTitleCode = jd.PositionCurrentTitleCode;
        } else {
          console.log(`no lookup info for ${p.lname}, ${p.fname}`);
        }
      });
    };
    const jdvs = this.people.map(p => p.JDVersionID);
    console.log("looking up ", jdvs);
    this.api.getJdInfoArray(jdvs).subscribe( 
      results => { 
        console.log("got jd lookup result ", results);
        addJdInfoToPeople(results);
      },
      error => this.errorService.logObject("lui/jdvs err:", error)
    );
  }

  private loadPeople() {
    this.api.getPeopleByUnit(this.deptCode).subscribe(
      people => {
        this.people = _.orderBy(people, ["lname", "fname"]);
        console.log("people ", people);
        this.loadJdInfo();
      },
      error => this.errorService.logObject("lui/people err: ", error)
    );
    this.api.getCurrentCycle().subscribe(
      cycle => this.currentCycle = cycle,
      error => this.errorService.logObject("lui/cycle err: ", error)
    )
  }

  private hasOpenEvals(aPerson) {
    return (!!aPerson.olderEvals) && 
      aPerson.olderEvals.some(spa => spa.evalStatus != "FI");
  }

  private newSpaDialog(p) {
    this.newSpaInfo.name = `${p.lname}, ${p.fname}`;
    this.newSpaInfo.empno = p.empno;
    this.newSpaInfo.cycle = this.DEFAULT_CYCLE;
    this.newSpaDialogVisible = true;
    console.log("opening new SPA dialog for ", p);
    console.log("newSpaInfo ", this.newSpaInfo);
  }

  private createSpa() {
    console.log("creating spa for ", this.newSpaInfo);
    const spaCreateRequest = 
      new SpaCreateRequest(this.newSpaInfo.empno, this.newSpaInfo.cycle);
    this.api.createSpa(spaCreateRequest).subscribe(
      result => {
        console.log("spa create result ", result);
        this.newSpaDialogVisible = false;
        this.loadPeople();
      }, 
      error => this.errorService.logObject("lui/createSpa err: ", error)
    )
  }

  ngOnDestroy() { this.routeSub.unsubscribe(); }

}
