import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from "rxjs";

import { ApiService } from "./api.service";
import { User }       from "./types/User";

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private userStore: ReplaySubject<User> = new ReplaySubject(1);
  
  constructor(private api: ApiService) { 
    console.log("in stateservice constructor");
    this.updateUserInfo();
  }

  /**
  * Reload user data from the server, presumably because the logged
  * in user has changed in a sandbox or QA environment.
  */
  public updateUserInfo() {
    this.api.getCurrentUser().subscribe(
      user => this.userStore.next(user),
      error => console.log("getCurrentUser in StateService error ", error)
    );
  }

  /**
  * Get an Observable that emits user objects as the current user changes
  * This wouldn't happen in Prod where the user isn't changeable.
  */
  public getCurrentUser(): Observable<User> {
    return this.userStore.asObservable();
  }
}
