import { OnInit, Component, OnDestroy } from '@angular/core';
import { fromEvent } from "rxjs";

import { SubSink } from "subsink";

import { ApiService } from "./api.service";
import { StateService } from "./state.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  private subs = new SubSink();

  user  = {};

  constructor(private api: ApiService, private state: StateService) {}

  ngOnInit() {
    this.subs.sink = this.state.getCurrentUser().subscribe(
      user => {
        console.log("current user from state", user);
        this.user = user;
      },
      err  => console.log("getCurrentUser() in app.component error: ", err)
    );
    // commenting out the keydown stuff for now
    /* 
    this.subs.sink = fromEvent(document, "keydown").subscribe(
      event => console.log(event),
      error => console.log("weird error from fromEvent ", error)
    );
    */
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
