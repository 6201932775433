import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SpaComponent }         from "./spa/spa.component";
import { HomeComponent}         from "./home/home.component";
import { UnitListComponent }    from "./unit-list/unit-list.component";
import { UnitHomeComponent }    from "./unit-home/unit-home.component";
import { LoginComponent }       from "./login/login.component";
import { StateInfoComponent }   from "./state-info/state-info.component";
import { RatingInfoComponent }  from "./rating-info/rating-info.component";
import { EmployeeComponent }    from "./employee/employee.component";
import { SignerListComponent }  from "./signer-list/signer-list.component";

const routes: Routes = [
  { path: "spa/:id",            component: SpaComponent },
  { path: "home"   ,            component: HomeComponent },
  { path: "unitlist",           component: UnitListComponent },
  { path: "employee/:empno",    component: EmployeeComponent },
  { path: "login",              component: LoginComponent },
  { path: "info/state",         component: StateInfoComponent },
  { path: "info/rating",        component: RatingInfoComponent },
  { path: "signerlist",         component: SignerListComponent },
  { path: "unitHome/:deptCode", component: UnitHomeComponent },

  { path: "", redirectTo: "/home", pathMatch: "full"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
