/**
* information about controls and whether they should be masked
*/

/**
* Todo:

* Emp self (not visible when self finalized)
*/

import { User } from "../types/User";

export class SpaControlMask {

  static readonly EMP_COMMENT_STATES = new Set(["CE", "PS"]);
  static readonly EMP_SELF_STATES    = new Set(["SD", "CP", "PR", "CE", "PS"]);

  readonly userIsSup    : boolean = false;
  readonly userIsEmp    : boolean = false;
  readonly userIsHr     : boolean = false;

  readonly HR_CONTROLS  : boolean;

  readonly SUP_CONTROLS : boolean;
  readonly SUP_EDIT     : boolean;

  readonly EMP_CONTROLS : boolean;
  readonly EMP_SELF     : boolean;
  readonly EMP_COMMENT  : boolean;

  constructor(private readonly spa, private readonly user: User) { 
    this.userIsSup    = this.user.empno == this.spa.supEmpno;
    this.userIsEmp    = this.user.empno == this.spa.empno;
    this.userIsHr     = this.user.hrRole;

    this.HR_CONTROLS  = this.userIsHr; // better if only for this deptcode
    this.SUP_CONTROLS = this.userIsSup;

    this.SUP_EDIT     = this.userIsSup && spa.evalStatus == "SD"; 

    this.EMP_SELF     = this.userIsEmp &&
      SpaControlMask.EMP_SELF_STATES.has(spa.evalStatus) &&
      spa.selfEvalStatus != "C";

    this.EMP_COMMENT  = this.userIsEmp && 
      SpaControlMask.EMP_COMMENT_STATES.has(spa.evalStatus) &&
      spa.employeeCommentStatus != "C";

    this.EMP_CONTROLS = this.userIsEmp;

    console.log("Spa Status: ", spa.evalStatus);
    console.log("ESS ", SpaControlMask.EMP_SELF_STATES);
  }


  /** default mask with everything off */
  static ALL_OFF_MASK = {
    HR_CONTROLS:  false,
    SUP_CONTROLS: false,
    SUP_EDIT:     false,
    EMP_SELF:     false,
    EMP_COMMENT:  false,
  };
}
