import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { SpaComponent } from "../spa/spa.component";

@Component({
  selector: 'app-html-edit',
  templateUrl: './html-edit.component.html',
  styleUrls: ['./html-edit.component.css']
})
export class HtmlEditComponent implements OnInit {

  @Output() editUpdate = new EventEmitter<any>(); // FIXME -- should be typed

  @Input() spa: any; // FIXME -- need to use a type here when one exists
  //@Input() textBuffer;
  //@Input() textFieldname;
  @Input() dialogOpen = false; 

  // TextEditInfo
  private _tei;
  @Input() set tei(aValue) {
    //console.log("set tei called with ", aValue);
    this._tei = aValue;
  }

  get tei() { return this._tei }

  private _textBuffer: string;
  
  constructor() { } 

  get textBuffer() {
    //console.log("he textBuffer get");
    return this._textBuffer;
  }

  @Input() set textBuffer(aValue) {
    //console.log("he textBuffer set with value ", aValue);
    this._textBuffer = aValue;
  }

  saveEdit() {
    //console.log("in HEC textBuffer is "  + this.textBuffer);
    //this.spaComponent.saveTextDialog(this.textBuffer);
    this.editUpdate.emit({
      action: "SAVE",
      textValue: this.textBuffer,
      textFieldname: this.tei.fieldname,
    });
  }

  cancelEdit() {
    //this.spaComponent.hideTextDialog();
    this.editUpdate.emit({
      action: "CANCELLED"
    });
  }

  ngOnInit() {
  }

}
