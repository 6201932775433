import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { switchMap, map } from "rxjs/operators";

import { SpaCreateRequest } from "./types/SpaCreateRequest";
import { User             } from "./types/User";

import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private readonly TEST_SPA_URL = "assets/TestSpa.json";
  private static readonly WITH_CREDS = { withCredentials: true };
  //private static readonly API_SERVER = "http://localhost:5001";
  private static readonly API_SERVER = "http://cygnusx1.ucsd.edu:5001";
  //private static readonly API_SERVER = "http://trainingadmin.spa.ucsd.edu:5001";

  constructor(private http: HttpClient) { }

  private get(path: string): Observable<any> {
    return this.http.get(`${ApiService.API_SERVER}${path}`,
      ApiService.WITH_CREDS);
  }

  private patch(path: string, patchData): Observable<any> {
    return this.http.patch(`${ApiService.API_SERVER}${path}`, patchData,
      ApiService.WITH_CREDS);
  }

  private post(path: string, postData): Observable<any> {
    return this.http.post(`${ApiService.API_SERVER}${path}`, postData,
      ApiService.WITH_CREDS);
  }


  public login(empno) {
    return this.post(`/login`, { realEmpno: -1000, empno: empno });
  }

  /** TEST/QA support method to automatically fill in SPA */
  public autofill(spaId): Observable<any> {
    return this.patch(`/eval/${spaId}/autofill`, {});
  }

  /** update the job description for this spa */
  public updateJd(spaId: number, newJdVersionId: number): Observable<any> {
    return this.patch(`/eval/${spaId}/jd/${newJdVersionId}`, {});
  }

  /** update the jd for a given employee */
  public updateEmployeeJd(empno: number, newJdVersionId: number): 
    Observable<any> {
      return this.patch(`/v2/employee/${empno}/jd/${newJdVersionId}`, {});
  }

  /** update the period covered for this spa */
  public updatePeriod(spaId: number, periodStart: Date, periodEnd: Date): Observable<any> {
    
    const FORMAT_STRING = "MM/DD/YYYY";
    const newStart = moment(periodStart).format(FORMAT_STRING);
    const newEnd = moment(periodEnd).format(FORMAT_STRING);
    return this.patch(`/eval/${spaId}/period`, {
      periodStart: newStart,
      periodEnd: newEnd,
      });
  }

  public updateSupervisor(spaId: number, newSupEmpno: number ): Observable<any> {

    return this.patch(`/eval/${spaId}/supervisor/${newSupEmpno}`, {});
  }

  public updateFunction(spaId: number, fnId: number, importance: string, 
      rating: string): Observable<any> {
    const patchData = {
      importance: importance,
      rating: rating,
      ratingMod: ""
    };
    return this.patch(`/eval/${spaId}/function/${fnId}`, patchData);
  }

  public updateCategory(spaId: number, catId: number, importance: string, 
      rating: string): Observable<any> {
    const patchData = {
      importance: importance,
      rating: rating,
      ratingMod: ""
    };
    return this.patch(`/eval/${spaId}/cat/${catId}`, patchData);
  }

  /**
  * add or remove the supervisor categories from the specified SPA
  */
  public changeSupCategories(spaId: number, action: "add" | "remove" ) {
    return this.patch(`/eval/${spaId}/cats/sup/${action}`, {});
  }

  public updateOverall(spaId: number, rating: string): Observable<any> {
    const patchData = {
      importance: "",
      rating: rating,
      ratingMod: ""
    };
    return this.patch(`/eval/${spaId}/overall`, patchData);
  }


  updateTextField(spaId: number, fieldName: string, newValue): Observable<any> {
    return this.patch(`/eval/${spaId}/text/${fieldName}`, { newValue:newValue});
  }

  sign(spaId): Observable<any> {
    return this.patch(`/eval/${spaId}/sign`, {});
  }

  createSpa(createData: SpaCreateRequest) {
    return this.post(`/eval`, createData);
  }

  batchCreateSpas(empnoList: Array<number>, cycle: string) {
    const body = { batchEmpnoList: empnoList, cycle: cycle };
    return this.post(`/eval`, body);
  }

  batchReleaseSpas(idList: Array<number>) {
    const body = { ids: idList, status: "R" };
    return this.patch("/eval/releaseStatus/batch", body);
  }

  setStatus(spaId: number, newStatus): Observable<any> {
    return this.patch(`/eval/${spaId}/status/${newStatus}`, {});
  }

  setSelfEvalStatus(spaId: number, newStatus): Observable<any> {
    return this.patch(`/eval/${spaId}/selfEvalStatus`, {
        selfEvalStatus: newStatus
      });
  }

  setEmployeeCommentStatus(spaId: number, newStatus): Observable<any> {
    return this.patch(`/eval/${spaId}/employeeCommentStatus`, {
        employeeCommentStatus: newStatus
      });
  }

  getSpaInfoBySigner(): Observable<any> {
    return this.smFromUser(user => 
      this.get(`/eval/evalInfo?signer=${user.empno}`));
  }
  
  getSpa(spaId: number): Observable<any> {
    return this.get(`/eval/${spaId}`);
  }

  getCurrentUser(): Observable<User> {
    return this.get(`/currentUser`);
  }

  getDeptInfo(deptCode: string): Observable<any> {
    return this.get(`/departments/${deptCode}`);
  }

  getPeopleByUnit(deptCode: string): Observable<any> {
    return this.get(`/people/${deptCode}`);
  }

  public getEmployeeByEmpno(empno: number) {
    return this.get(`/v2/employee/${empno}`);
  }

  getJdByJdNumber(jdNumber: number, jdVersion: number): Observable<any> {
    return this.get(`/v2/jd/${jdNumber}/${jdVersion}`);
  }

  getJdInfoArray(jdVersionArray: Array<number>) {
    const arrayParam = JSON.stringify(jdVersionArray);
    return this.get(`/v2/jd?jdVersionIdArray=${arrayParam}`);
  }

  getSupervisors(lname: string, dept_code: string = undefined): Observable<any> {
    let qString = "supervisors=true";
    qString = dept_code ? `${qString}&dept_code=${dept_code}` : qString;
    qString = lname     ? `${qString}&lname=${lname}`         : qString;
    console.log("qstring ", qString);
    return this.get(`/v2/people?${qString}`);
  }

  getCurrentCycle(): Observable<any> {
    return this.get(`/currentCycle`).pipe( map(result => result.cycle) );
  }


  /**
  * get the current user, then pipe to the passed function
  */
  private smFromUser(fn: (user: any) => Observable<any>): Observable<any> {
    return this.getCurrentUser().pipe(switchMap(fn));
  }

  getBasicInfoBySup(): Observable<any> {
    return this.smFromUser(user => this.get(`/supEvaluations/${user.empno}`));
  }

  getIndirectBasicInfoBySup(): Observable<any> {
    return this.smFromUser(user => this.get(`/indirectEvaluations/${user.empno}`));
  }

  getAllDeptInfo(): Observable<any> {
    return this.smFromUser(user => this.get(`/user/${user.empno}/departments`));
  }

  getCurrentUserSpaList() {
    return this.smFromUser(user => this.get(`/allEvaluations/${user.empno}`));
  }

  getCurrentUserLatestSpa() {
    return this.smFromUser(user => this.get(`/latestEvaluation/${user.empno}`));
  }
}
