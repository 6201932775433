import { Component, OnInit } from '@angular/core';

import { SPA_STATUS_LIST } from "../spa/states";

@Component({
  selector: 'app-state-info',
  templateUrl: './state-info.component.html',
  styleUrls: ['./state-info.component.css']
})
export class StateInfoComponent implements OnInit {

  private readonly SPA_STATUS_LIST = SPA_STATUS_LIST;

  constructor() { }

  ngOnInit() {
  }

}
