import { Component, OnInit } from '@angular/core';

import { STANDARDS } from "../spa/standards";

@Component({
  selector: 'app-rating-info',
  templateUrl: './rating-info.component.html',
  styleUrls: ['./rating-info.component.css']
})
export class RatingInfoComponent implements OnInit {

  private readonly STANDARDS = STANDARDS;

  constructor() { }

  ngOnInit() {
  }

}
