import { Component, OnInit } from '@angular/core';
import { ApiService        } from "../api.service";

@Component({
  selector: 'app-signer-list',
  templateUrl: './signer-list.component.html',
  styleUrls: ['./signer-list.component.css']
})
export class SignerListComponent implements OnInit {

  spaInfos = [];

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.api.getSpaInfoBySigner().subscribe(
      spaInfos => this.spaInfos = spaInfos,
      error    => console.log("signerList load error ", error)
    );
  }

}
