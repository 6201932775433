import { Component, OnInit, Optional, Input } from '@angular/core';
import { SpaComponent      } from "../spa/spa.component";

@Component({
  selector: 'app-rating-edit',
  templateUrl: './rating-edit.component.html',
  styleUrls: ['./rating-edit.component.css'],
})
export class RatingEditComponent implements OnInit {

  private _rating: string;

  @Input() spa        : any;
  @Input() description: string;
  @Input() title      : string;
  @Input() scoreType  : string;
  @Input() importance : string;
  @Input() standard   : any;

  @Input() set rating(value: string) {
    this._rating = value;
    const scoreInfoList 
      = this.standard.scoreInfo.filter(si => si.rating == value);
    console.log(" setting standard", this.standard);
    console.log("std rating ", scoreInfoList);
    if ( scoreInfoList.length == 1 ) {
      this.selectedScoreInfo = scoreInfoList[0];
    } else {
      console.log("rating-edit setting score to blank in table");
      this.selectedScoreInfo = undefined;

    }
  }

  get rating(): string { return this._rating; }

  selectedScoreInfo;

  constructor(@Optional() private spaComponent: SpaComponent) { }

  ngOnInit() { }

  onRowSelect(event) {
    console.log("selection event: ", event);
    console.log("event data for rating is ", event.data.rating);
    console.log("selected Score object is ", this.selectedScoreInfo);
    this.spaComponent.scoreEditInfo.rating =  this.selectedScoreInfo.rating;
  }

  onRowUnSelect(event) {
    console.log("UNselection event: ", event);
  }

  saveScore() {
    this.spaComponent.saveScoreDialog(this.importance, this.rating);
  }

  cancelEdit() {
    console.log("cancelling edit from rating-edit");
    this.spaComponent.cancelScoreDialog();
  }


}
