import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { timer } from "rxjs";

import { ApiService } from "../api.service";
import { StateService } from "../state.service";
import { User } from "../types/User";

import { SubSink } from "subsink";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  private subs = new SubSink();

  private logins = [
    { name: "London Holly", empno: 1215 },
    { name: "London Pam", empno: 1204 },
    { name: "London Toby", empno: 1212 },
    { name: "London Michael", empno: 1201 },
    { name: "London Jim", empno: 1203 },
  ];

  private user: User = User.empty;

  constructor(private api: ApiService, private state: StateService) { }

  login(aLogin) {
    console.log("trying to login ", aLogin);
    this.api.login(aLogin.empno).subscribe(
      result => { 
        console.log("login result: ", result);
        this.state.updateUserInfo();
      },
      error => console.log("login error ", error)
    );
  }

  ngOnInit() {
    this.subs.sink = this.state.getCurrentUser().subscribe(
      user => this.user = user,
      error => console.log("getCurUser() error in login comp ", error)
    );
  }
}
