/**
* information about fields of a Spa
*/

class Field {
  constructor( 
    public name: string,
    public title: string,
    public controlType: string,
    public description: string,
    public order: number,
    public section: string,
  ) {}

  static UNKNOWN = new Field("unknown", "unknown field", "NONE", "unknown field", 0, "XX");
}

const fields: Field[] = [
  { name: "supportComments", 
    title: "COMMENTS SUPPORTING THE OVERALL APPRAISAL OF PERFORMANCE",
    controlType: "SUP_EDIT",
    description: "",
    order: 1,
    section: "IV",
  },
  { name: "futurePlans", 
    title: "FUTURE PLANS/ACTIONS/GOALS",
    controlType: "SUP_EDIT",
    description: "Discuss future growth, employee development and other plans/actions dictated by the evaluation.",
    order: 2,
    section: "V",
  },
  { name: "selfGoals", 
    title: "Self Appraisal Functions/Goals/Projects  ",
    controlType: "EMP_SELF",
    description: "",
    order: 3,
    section: "VIa",
  },
  { name: "selfAccomplishments", 
    title: "Self Appraisal Accomplishments and Comments",
    controlType: "EMP_SELF",
    description: "",
    order: 4,
    section: "VIb",
  },
  { name: "employeeComments", 
    title: "Employee Comments",
    controlType: "EMP_COMMENT",
    description: "",
    order: 5,
    section: "VII",
  },
];

const fieldMap = fields.reduce(
  (acc, cur) => {
    acc[cur.name] = cur;
    return acc;
  }, 
  {}
);


export class FieldInfo {
  readonly list = fields;
  lookup(aFieldname: string) {
    return fieldMap[aFieldname] || Field.UNKNOWN;
  }
}
