import { Component, OnInit, Input, Optional, Output, EventEmitter } from '@angular/core';

import { ConfirmationService } from "primeng/api";

import { ApiService } from "../api.service";

@Component({
  selector: 'app-person-select',
  templateUrl: './person-select.component.html',
  styleUrls: ['./person-select.component.css']
})
export class PersonSelectComponent implements OnInit {

  @Output() selection = new EventEmitter<any>(); // FIXME -- should be Person?

  @Input() dialogOpen: boolean;
  @Input() headerString = "select person";
  @Input() searchDepartment: string;


  private searchResults = [];
  private searchLname;
  private searchOutOfDept = false;
  private selectedPerson;


  constructor(private api: ApiService, 
    private confirmationService: ConfirmationService) { }

  ngOnInit() {}

  private clearResults() {
    this.searchResults = [];
    this.searchLname = "";
    this.searchOutOfDept = false;
    this.selectedPerson = undefined;
  }

  private search() {
    const deptToSearch = 
      this.searchOutOfDept ? undefined : this.searchDepartment;

    this.api.getSupervisors(this.searchLname, deptToSearch).subscribe(
      supervisors => { 
        console.log("search results ", supervisors);
        if ( supervisors.length === 0 ) {
         this.confirmationService.confirm({
            message: "No matching people found...",
            rejectVisible: false,
            acceptLabel: "OK",
          });
        }
        this.searchResults = supervisors;
      },
      error => console.log("search error: ", error)
    );
  }

  private personSelected() {
    this.selection.emit({ 
      action: "SELECTED", 
      empno: this.selectedPerson.empno }
    );
  }

  private selectionCancelled() {
    this.clearResults();
    this.selection.emit({ 
        action: "CANCELLED",
      });

  }

}
