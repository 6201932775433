export const SPA_STATUS_LIST = [
      { 
        stateCode: "SD", name: "SUP DRAFT", supNext: "CP",
        longDescription: `Supervisor is drafting the appraisal.  Employee can 
        be drafting their self appraisal (until they finalize it). 
        `.trim(),
        hrNext: ["CP"],
        defaultHrNext: "CP",
        description:"Supervisor Draft",
        statusNum: 1,
        supEdit: true, empEdit: true, hrMod: true, empViewScores: false,
      },
      { 
        stateCode: "CP", name: "HR REVIEW", 
        longDescription: `
          The drafted appraisal scores have been submitted for review by
          the unit's HR contacts.  Supervisor can no longer edit scores.
          Employee can
          be drafting their self appraisal (until they finalize it).  
        `.trim(),
          supNote: "Awaiting Review Completion",
          hrNext: ["PR", "SD"],
        defaultHrNext: "PR",
          description:"Home department HR Review",
      statusNum: 2,
        supEdit: false, empEdit: true, hrMod: true, empViewScores: false,
      },
      { 
        stateCode: "PR", name: "POST REVIEW", supNext: "CE",
        longDescription: `
          Review by unit HR contact is complete (including any unit specific
          additional review).  
        `.trim(),
          supNote: "Awaiting Discussion",
          description:"Awaiting supervisor Release",
      statusNum: 3,
          hrNext: ["CP", "SD", "CE"],
        defaultHrNext: "CE",
        supEdit: false, empEdit: true, hrMod: true, empViewScores: false,
      },
      { 
        stateCode: "CE", name: "DISCUSSION", supNext:"PS",
        longDescription: `The appraisal is being discussed with the employee.
        The employee can no longer edit their self appraisal.  The employee
        can edit their comments (until they are finalized).
        `.trim(),
          hrNext: ["PR", "CP", "SD", "PS"],
        defaultHrNext: "PS",
          description:"Being discussed with employee",
      statusNum: 4,
        supEdit: false, empEdit: true, hrMod: true, empViewScores: true,
      },
      { 
        stateCode: "PS", name: "PREP FOR SIGNATURE", 
        longDescription: `
          The unit's HR contacts are preparing the appraisal for electronic
          signatures.
        `.trim(),
          supNote: "Awaiting HR Route for Signatures",
          hrNext: ["S", "CP", "CE", "PR", "CP", "SD"],
        defaultHrNext: "S",
          description:"Home department HR preparing for signature",
      statusNum: 5,
        supEdit: false, empEdit: true, hrMod: false, empViewScores: true,
      },
      { 
        stateCode: "S", name: "SIGNING", 
        longDescription: `
          Designated signers have been notified and the appraisal is waiting
          for all signatures to be complete.
        `.trim(),
          supNote: "Awaiting Signatures",
          description:"Available for signing",
      statusNum: 6,
          hrNext: ["CP"],
        defaultHrNext: undefined,
        supEdit: false, empEdit: false, hrMod: true, empViewScores: true,
      },
      { 
        stateCode: "FI", name: "COMPLETE",
        longDescription: `
          The appraisal is complete.  All required signatures have been
          recorded.  
        `.trim(),
          supNote: "Complete.",
          hrNext: ["PS"],
        defaultHrNext: undefined,
          description:"Complete and archived online",
      statusNum: 7,
        supEdit: false, empEdit: false, hrMod: false, empViewScores: true,
      }, 
      { 
        stateCode: "A",  name: "ABANDONED",
        longDescription: `
          This appraisal has been abandoned.  It will not be completed and
          may be deleted.
        `.trim(),
          hrNext: ["SD"],
          hrOnly: true,
        defaultHrNext: undefined,
          description: "Abandoned -- unstarted or incomplete",
          statusNum: 8,
        supEdit: false, empEdit: false, hrMod: false, empViewScores: false,
      }
];

export const SPA_STATUS_LOOKUP = {};
SPA_STATUS_LIST.forEach(s => SPA_STATUS_LOOKUP[s.stateCode] = s);

