import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CardModule } from "primeng/card";
import { DialogModule } from "primeng/dialog";
import { EditorModule } from "primeng/editor";
import { ButtonModule } from "primeng/button";
import { ToolbarModule } from "primeng/toolbar";
import { SplitButtonModule } from "primeng/splitbutton";
import { RadioButtonModule } from "primeng/radiobutton";
import { BlockUIModule     } from "primeng/blockui";
import { TableModule  } from "primeng/table";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmationService } from "primeng/api";
import { DropdownModule } from "primeng/dropdown";
import { TooltipModule } from "primeng/tooltip";
import { PanelModule } from "primeng/panel";
import { InputTextModule } from "primeng/inputtext";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { ListboxModule } from "primeng/listbox";
import { InputMaskModule } from "primeng/inputmask";
import { MenubarModule }   from "primeng/menubar";
import { CalendarModule }  from "primeng/calendar";
import { TabViewModule } from "primeng/tabview";

import { SpaComponent } from './spa/spa.component';
import { HomeComponent } from './home/home.component';
import { RatingEditComponent } from './rating-edit/rating-edit.component';
import { HtmlEditComponent } from './html-edit/html-edit.component';
import { UnitListComponent } from './unit-list/unit-list.component';
import { UnitHomeComponent } from './unit-home/unit-home.component';
import { LoginComponent } from './login/login.component';
import { PersonSelectComponent } from './person-select/person-select.component';
import { JdSelectComponent } from './jd-select/jd-select.component';
import { StateInfoComponent } from './state-info/state-info.component';
import { RatingInfoComponent } from './rating-info/rating-info.component';
import { EmployeeComponent } from './employee/employee.component';
import { SignerListComponent } from './signer-list/signer-list.component';

@NgModule({
  declarations: [
    AppComponent,
    SpaComponent,
    HomeComponent,
    RatingEditComponent,
    HtmlEditComponent,
    UnitListComponent,
    UnitHomeComponent,
    LoginComponent,
    PersonSelectComponent,
    JdSelectComponent,
    StateInfoComponent,
    RatingInfoComponent,
    EmployeeComponent,
    SignerListComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CardModule,
    DialogModule,
    EditorModule,
    ButtonModule,
    ToolbarModule,
    RadioButtonModule,
    SplitButtonModule,
    BlockUIModule,
    TableModule,
    ConfirmDialogModule,
    DropdownModule,
    TooltipModule,
    PanelModule,
    InputTextModule,
    ScrollPanelModule,
    ListboxModule,
    InputMaskModule,
    MenubarModule,
    CalendarModule,
    TabViewModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
