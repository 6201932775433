import { Component }          from '@angular/core';
import { OnInit, OnDestroy }  from '@angular/core';
import { ActivatedRoute }     from "@angular/router";
import { Title }              from "@angular/platform-browser";

import { SubSink }            from "subsink";

import { ApiService }         from "../api.service";

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit, OnDestroy {

  private subs = new SubSink();

  private jdDialogDisplay = false;

  private empno;
  private employee: any;

  constructor(
      private route: ActivatedRoute,
      private titleService: Title,
      private api: ApiService,
    ) { 
      this.titleService.setTitle(
        "UCSD Staff Performance Appraisals - Employee Overview");
    }

  ngOnInit() {
    this.subs.sink = this.route.params.subscribe( params => {
      //const empno = +params["empno"];
      this.empno = +params["empno"];
      this.loadEmployee();
      /*
      this.api.getEmployeeByEmpno(empno).subscribe(
        employee => this.employee = employee,
        error => console.log("error in employee comp getting emp api ", error)
      );
      */
    });

  }

  private loadEmployee() {
    this.api.getEmployeeByEmpno(this.empno).subscribe(
      employee => this.employee = employee,
      error => console.log("error in employee comp getting emp api ", error)
    );
  }

  ngOnDestroy() { this.subs.unsubscribe(); }

  openJdDialog() {
    this.jdDialogDisplay = true;
  }

  jdSelectAction(action) {
    console.log("employee got action from jd ", action);
    this.jdDialogDisplay = false;
    if ( action.action == "SELECTED" ) {
      const newJdVersionId = action.jd.JDVersionID
      this.api.updateEmployeeJd(this.employee.empno, newJdVersionId).subscribe(
        result => this.loadEmployee(),
        error => console.log("jd change error ", error)
      );
    }
  }

}
